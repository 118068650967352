.now-playing {
  width: 100%;
  text-align: left;
  position: relative;
  top: 0;
  left: 0;
  height: 40px;
  overflow: hidden;
  display: grid;
  place-content: center;

  p {
    margin: 0;
    
    &.message {
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
    }
    &.title {
      position: absolute;
      left: 0;
      top: 4px;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.artist{
      position: absolute;
      left: 0;
      top: 22px;
      font-size: 11px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

}

@keyframes floatText {
  0% {
    left: 00%;
  }
  50% {
    left: -50%;
  }
  100% {
    left: 00%;
  }
}