.full-player-wrapper {
  display: grid;
  place-items: center;
  min-height: 100vh;
  min-height: calc(100vh - 55px);
}

.full-player {
  background: #fff;
  color: #000;
  height: auto;
  width: 95%;
  max-width: 350px;
  border-radius: 10px;
  z-index: 2999;
  box-shadow: 2px 2px 5px 0px rgba(255, 176, 0,0.20);
  position: relative;
  top: 0;
  left: 0;

  .wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    margin: 0 auto;
    padding: 5px 0;
  }

  .player-mute-button,
  .player-play-button,
  .player-refresh-button {
    position: absolute;
  }
  .player-mute-button {
    top: -15px;
    left: 0;
  }

  .player-refresh-button {
    top: -15px;
    right: 0;
  }

  .player-play-button {
    top: -28px;
    left: 40px;
  }

  .now-playing {
    width: 175px;
    margin-left: 100px;
    margin-bottom: 10px;
  }

  ion-grid {
    padding: 0;
  }

}