.player-station-button {
  --background: #000;
  --color: #fff;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  min-width: 40px;
  width: 40px;
  max-width: 40px;
  min-height: 40px;
  height: 40px;
  max-height: 40px;
  margin: 0;
  font-size: 16px;
  &.active {
    --color: var(--ion-color-primary); 
  }
}