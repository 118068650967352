@import 'src/assets/scss/variables.scss';

.network-status {

  width: 100%;
  position: fixed;
  bottom: 150px;
  text-align: center;
  z-index: 2001;

  ion-button {
    --background: #7d15da;
    --background: linear-gradient(to right, #7d15da 0%, #7d15da 50%, #3e0a7c 100%);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 50px;
    --padding-end: 30px;
    --border-radius: 30px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    text-align: center;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    height: 50px;
    ion-icon {
      position: absolute;
      top: 2px;
      left: -25px;
      font-size: 25px;
    }
  }

}