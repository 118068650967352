.main-header {

  ion-toolbar {
    --background: var(--ion-color-primary);
  }

  ion-button,
  ion-menu-button {
    --color: #fff;
  }

  .brand-logo {
    display: block;
    /* margin: 0 auto; */
    width: 60px;
    text-align: center;
    position: absolute;
    top: 0;
    left: calc(50vw - 30px);
    ion-icon {
      font-size: 48px;
    }
  }

  ion-menu-button {
    font-size: 26px;
  }

  .return-button {
    ion-icon {
      transform: rotate(180deg);
    }
  }

  .search-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    --background: #1c1c1c; 
    transition: width 1s;
    width: 0;
    padding-top: var(--ion-safe-area-top, 0);
    &.active {
      width: 100%;
    }

    ion-input {
      border-radius: 25px;
      width: 95%;
      --background: var(--ion-background-color);
      --padding-start: 20px;
      --padding-end: 20px;
    }
  }
}