.main-menu {

  --width: 350px;
  
  ion-content, 
  ion-list,
  ion-item,
  ion-toolbar {
    --background: var(--ion-color-primary);
    background: var(--ion-color-primary);
  }

  ion-menu-toggle {
    ion-button {
      &.rounded-button {
        color: --ion-color-primary !important;
      }
      --color: #fff;
    }
  }

  .categories {
    border: 0 solid #000;
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;
    &.ios {
      border-top-width: 0px;
    }
    ion-item {
      ion-label {
        font-size: 14px !important;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        cursor: pointer;
      }
      a {
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        
      }
      ion-icon {
        font-size: 14px;
        color: #fff;
        transform: rotate(0deg);
        transition: 1s;
        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }

  .subcategories {
    padding: 0;
    max-height: 0;
    transition: max-height 1s;
    overflow: hidden;
    &.active {
      max-height: 100vh;
    }
    ion-item {
      ion-label {
        font-size: 14px !important;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 4px;
        cursor: pointer;
        padding-left: 20px;
      }
      a {
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 4px;
        
      }
    }
  }

  .buttons-container {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 56px;
    gap: 0px 0px;
    grid-template-areas:
      ". . . . .";
    padding-top: 15px;

    ion-menu-toggle {
      ion-button.rounded-button {
        --color: var(--ion-color-primary);
      }
    }  

    .rounded-button {
      display: block;
      margin: 0 auto;
      ion-icon {
        font-size: 20px;
      }
    }
  }

  .terms-button {
    display: block;
    margin: 20px auto;
    width: 200px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
  }

  .get-the-app {
    margin: 15px auto;
    display: block;
    figure {
      margin: 0 0 20px 0;
      a {
        display: block;
        width: 140px;
        margin: 0 auto;
      }
      ion-img {
        width: 140px;
        margin: 0 auto;
      }
    }
  }
}

@media(max-width: 600px) {
  .main-menu {
    --width: 100% !important;
    --min-width: 100% !important;
  }
}

@media(max-width: 340px) {
  .menu-inner {
    --width: 100% !important;
    --min-width: 100% !important;
  }
}

@media (max-width: 340px) {
  .main-menu {
    .menu-inner {
      --width: 100% !important;
      --min-width: 100% !important;
    }
  }
}