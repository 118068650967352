body {
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }
}
.page-wrapper {
  --padding-bottom: 120px;
}

@media (max-width: 991px) {
  .page-wrapper {
    --padding-bottom: 155px;
  }
}

ion-header {
  ion-button {
    width: 48px;
    height: 48px !important;
    margin: 0 !important;
    --border-radius: 50% !important;
    ion-icon {
      font-size: 26px;
    }
  }
}

/* ion-content {
} */

.rounded-button {
  --background: #fff;
  --color: var(--ion-color-primary);
  --border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 0;
  ion-icon {
    font-size: 26px;
  }
}

.center-view-content {
  display: grid !important;
  place-items: center;
}

.flexible-width-content {
  display: block;
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .flexible-width-content {
    width: 90%;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .flexible-width-content {
    width: 80%;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .flexible-width-content {
    width: 70%;
  }
}

.center-wrapper {
  width: 95%;
  max-width: 1080px;
  margin: 10px auto;
}

@media (max-width: 399px) {
  .center-wrapper {
    width: 97%;
  }
}

.block1,
.block2,
.block3,
.block4,
.block5,
.block6,
.block7,
.block8,
.text-content,
.embed-content,
.image-content {
  display: block;
  width: 100%;
}

.embed-responsive-wrapper {
  position: relative;
  top: 0;
  left: 0;

  iframe[src*="anchor"] {
    width: 100%;
    height: auto;
  }

  twitter-widget,
  iframe,
  embed,
  object,
  video {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.simplebar-scrollbar::before {
  background-color: var(--ion-color-primary) !important;
}

.embed-responsive {
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  .fb_iframe_widget,
  iframe,
  embed,
  object,
  video,
  div[data-player] {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.radio-title {
  color: #fff;
  border: 0 solid var(--ion-color-primary);
  border-bottom-width: 2px;
  display: inline-block;
  letter-spacing: 1.5px;
  margin: 10px 0 15px 0;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 20px;
  padding-bottom: 5px;
}

.radio-title-alt {
  color: var(--ion-color-primary);
  display: inline-block;
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0px;
  text-align: left;
  text-transform: uppercase;
  padding: 0 5px 5px 0;
}

@media (min-width: 0px) and (max-width: 540px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_540x960.png) left
      top/cover no-repeat fixed;
  }
  /*
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_540x960.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_540x960.png) left
      top/cover no-repeat fixed;
  }
  */
}
@media (min-width: 541px) and (max-width: 600px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_600x1024.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_600x1024.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_600x1024.png) left
      top/cover no-repeat fixed;
  }
 */
}
@media (min-width: 601px) and (max-width: 720px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_720x1280.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_720x1280.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_720x1280.png) left
      top/cover no-repeat fixed;
  }
 */
}
@media (min-width: 721px) and (max-width: 960px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_960x540.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_960x540.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_960x540.png) left
      top/cover no-repeat fixed;
  }
 */
}
@media (min-width: 961px) and (max-width: 1024px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_1024x600.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_1024x600.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_1024x600.png) left
      top/cover no-repeat fixed;
  }
  */
}
@media (min-width: 1025px) and (max-width: 1080px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_1080x1920.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_1080x1920.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_1080x1920.png) left
      top/cover no-repeat fixed;
  }
   */
}
@media (min-width: 1081px) and (max-width: 1280px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_1280x720.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_1280x720.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_1280x720.png) left
      top/cover no-repeat fixed;
  }
  */
}
@media (min-width: 1281px) and (max-width: 1920px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_1920x1080.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_1920x1080.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_1920x1080.png) left
      top/cover no-repeat fixed;
  }
 */
}
@media (min-width: 1921px) and (max-width: 2160px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_2160x4096.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_2160x4096.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_2160x4096.png) left
      top/cover no-repeat fixed;
  }
   */
}
@media (min-width: 2161px) and (max-width: 4096px) {
  ion-content {
    --background: #000 url(./assets/img/backgrounds/amarillo_4096x2160.png) left
      top/cover no-repeat fixed;
  }
  /* 
  .yellow-circles {
    --background: #000 url(./assets/img/backgrounds/amarillo_4096x2160.png) left
      top/cover no-repeat fixed;
  }
  .white-circles {
    --background: #000 url(./assets/img/backgrounds/blanco_4096x2160.png) left
      top/cover no-repeat fixed;
  } 
  */
}

#clappr {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 320px;
  margin-bottom: 25px;
}
#clappr > div {
  width: 100%;
  height: 100%;
  position: absolute;
}
