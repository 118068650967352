.station-page {

  .content-wrapper {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    color: #fff;
    max-width: 768px;
    width: 90%;
    margin: 0 auto; 
    padding: 20px 0;
  
    .slideshow {
      display: grid;
      grid-template-columns: 40% 60%;

      ion-img {
        max-width: 300px;
        height: auto;
        margin: 0 auto;
        border-radius: 5px;
        overflow: hidden;
        display: block;
      }

      .title {
        font-weight: 700;
        line-height: 1.1;
        letter-spacing: 1px;
        margin: 0 0 20px 0;
      }

      .file-container {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 20px;
        border: 0 solid var(--ion-color-primary);
        border-bottom-width: 2px;
        padding: 0 0 20px 50px;
        min-height: 50px;

        &:last-of-type {
          border-bottom-width: 0px;
        }

        .button-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          height: 50px;

        }

        .subtitle {
          font-size: 18px;
          margin: 0 auto 5px;
        }
      
        .intro {
          margin: 0px;
          font-size: 0.9em;
          p {
            margin: 0 auto 5px auto;
          }
        }
      }

      figure {
        margin: 0;
      }

      figcaption {
        padding: 5px 10px;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
  
}


@media (max-width: 767px) {
  .station-page {
    .content-wrapper {
      .slideshow {
        display: block;
        figure {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  .station-page {
    .title {
      font-size: 1.4rem;
    }
    .intro {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 399px) {
  .station-page {
    .title {
      font-size: 1.3rem;
    }
  }
}