.playback-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px;
  padding: 0px 85px 5px 85px;
  transition: height 1s ease;
  overflow: hidden;
  
  &.none {
    height: 0;
    color: #fff;
  }

  ion-button {
    position: absolute;
    top: 0px;
    min-width: 30px;
    width: 30px;
    max-width: 30px;
    min-height: 30px;
    height: 30px;
    max-height: 30px;
    margin: 0;
    font-size: 18px;
    --color: #000;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 50%;
  }

  .wind-back {
    left: 0;
  }
  .wind-fordward {
    right: 0;
  }

  ion-label {
    position: absolute;
    top: 0px;
    font-size: 0.80em;
    line-height: 33px;
    color: #000;
  }

  .time-elapsed {
    left: 35px;
  }
  .time-left {
    right: 35px;
  }

  ion-range {
    padding: 0px;
    --height: 30px;
    --padding-start: 0;
    --knob-background: var(--ion-color-primary);
    --knob-size: 20px;
    --bar-background-active: var(--ion-color-primary);
    --bar-background: rgba(255, 179, 0, 0.3);
  }
}