.player-content-button {
  background-color: var(--ion-color-primary);
  background: linear-gradient(90deg, var(--ion-color-primary) 50%, var(--ion-color-secondary) 100%);
  border-radius: 50%;
  width: 40px;
  height: 40px;

  ion-button {
    --background: var(--ion-color-primary);
    --background: linear-gradient(90deg, var(--ion-color-primary) 50%, var(--ion-color-secondary) 100%);
    --color: #fff;
    --border-radius: 50%;
    --padding-start: 5px;
    --padding-end: 5px;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    min-height: 40px;
    height: 40px;
    max-height: 40px;
    margin: 0;
  }

  &.lg {
    width: 48px;
    height: 48px;

    ion-button {
      min-width: 48px;
      width: 48px;
      max-width: 48px;
      min-height: 48px;
      height: 48px;
      max-height: 48px;
    }
  }
  

  &.large {
    width: 300px;
    height: 55px;
    border-radius: 35px;
    margin: 0 auto 20px auto;
    display: block;

    ion-button {
      --background: var(--ion-color-primary);
      --background: linear-gradient(90deg, var(--ion-color-primary) 50%, var(--ion-color-secondary) 100%);
      --color: #fff;
      --padding-top: 5px;
      --padding-end: 15px;
      --padding-start: 15px;
      --padding-bottom: 5px;
      --border-radius: 35px;
      font-weight: 700;
      text-transform: none;
      letter-spacing: 1.4px;
      font-size: 25px;
      line-height: 30px;
      min-width: 300px;
      width: 300px;
      max-width: 300px;
      min-height: 55px;
      height: 55px;
      max-height: 55px;
      margin: 0;
      ion-icon {
        padding-left: 5px;
      }
    }

  }
}