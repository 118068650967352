.minimal-player-wrapper {
  display: grid;
  place-items: center;
  min-height: 100vh;
  min-height: calc(100vh - 55px);
}

.minimal-player {
  background: #fff;
  color: #000;
  height: auto;
  width: 95%;
  max-width: 350px;
  border-radius: 10px;
  z-index: 2999;
  box-shadow: 2px 2px 5px 0px rgba(255, 176, 0,0.20);
  position: relative;
  top: 0;
  left: 0;

  .wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    margin: 0 auto;
    padding: 5px 0;
  }

  .player-mute-button,
  .player-play-button,
  .player-refresh-button {
    position: absolute;
  }
  .player-mute-button {
    top: -15px;
    left: 0;
  }

  .player-refresh-button {
    top: -15px;
    right: 0;
  }

  .player-play-button {
    top: -28px;
    left: 40px;
  }

  .now-playing {
    width: 190px;
    margin-left: 100px;
  }

  .out-of-line {
    width: 185px;
    margin-left: 115px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 700;
    p {
      font-size: 0.85em;
      margin: 0;
    }
    
  }

  ion-grid {
    padding: 0;
  }

  .identify {
    height: 40px;
    text-align: center;
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    padding-left: 35px;
    p {
      margin: 7px 0px 0 0px;
    }
    ion-icon {
      font-size: 30px;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }

  .player-station-button {
    --background: #000;
    --color: #fff;
    --border-radius: 50%;
    --padding-start: 5px;
    --padding-end: 5px;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    min-height: 40px;
    height: 40px;
    max-height: 40px;
    margin: 0;
  }

}