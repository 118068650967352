.player-refresh-button {
  --background: var(--ion-color-primary);
  --color: #fff;
  --border-radius: 50%;
  --padding-start: 5px;
  --padding-end: 5px;
  --box-shadow: 2px -2px 5px 0px rgba(0,0,0,0.75);
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  min-height: 30px;
  height: 30px;
  max-height: 30px;
  margin: 0;
}