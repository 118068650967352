
html.ios  {
  .playlist-player {
    padding-bottom: 15px;
  }
}


.playlist-player {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  color: #000;
  width: 100%;
  border-radius: 10px 10px 0 0;
  z-index: 100;
  box-shadow: 2px -2px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 5px;
  height: 0;
  display: none;

  &.active {
    height: auto;
    display: block;
  }

  .wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 98%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0;
  }

  .nowplaying-wrapper {
    width: 300px;
    margin: 0px auto 5px auto;
  }

  .playback-wrapper {
    width: 300px;
    margin: 0 auto;
  }

  .stations-wrapper {
    width: 300px;
    margin: 0 auto;
  }

  .player-mute-button,
  .player-play-button,
  .player-refresh-button {
    position: absolute;
  }
  .player-mute-button {
    bottom: 30px;
    left: 0;
  }

  .player-play-button {
    bottom: 17px;
    left: 50px;
  }

  .player-refresh-button {
    bottom: 30px;
    left: 126px;
  }

}

/* @media(max-width:1199px) {
  .playlist-player {
    
  }
} */

@media(max-width:991px) {
  .playlist-player {
    width: 350px;
    left: calc(50% - 175px);

    .wrapper {
      width: 300px;
    }

    .nowplaying-wrapper {
      margin: 0px auto;
    }
  
    .playback-wrapper {
      width: 300px;
      position: relative;
      bottom: unset;
      right: unset;
    }
  
    .player-mute-button,
    .player-play-button,
    .player-refresh-button {
      position: absolute;
    }
    .player-mute-button {
      top: -15px;
      left: 0;
      bottom: unset;
    }

    .player-play-button {
      top: -28px;
      left: 40px;
      bottom: unset;
    }
  
    .player-refresh-button {
      top: -15px;
      right: 0;
      bottom: unset;
      left: unset;
    }
  
    .now-playing {
      width: 190px;
      margin-left: 100px;
    }
  }
}

@media(max-width:575px) {
  .playlist-player {
    width: 100%;
    left: 0px;
  }
}
