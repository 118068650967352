@import 'src/assets/scss/variables.scss';

.installable-banner {

	z-index: 3000;
	display: block;
  position: fixed;
  top: 74px;
  right: 0;
  width: 0px;
  height: 110px;
  max-height: 110px;
  overflow: hidden;
  border-radius: 60px 0 0 60px;
  text-align: center;
  color: #fff;
  background: #7d15da;
  background: linear-gradient(to right, #7d15da 0%, #7d15da 50%, #3e0a7c 100%);
  transition: width 1s ease;

  .button-wrapper {
    display: block;
    text-align: right;
    ion-button {
      --border-radius: 50%;
      --background: #fff;
      color: #000;
      padding: 0;
      margin: 5px 5px 0 5px;
      min-height: 30px;
      height: 30px;
      max-height: 30px;
      min-width: 30px;
      width: 30px;
      max-width: 30px;
      font-size: 5px;
      ion-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .action-wrapper {
    display: flex;
    padding: 0 0 5px 30px;
    ion-button {
      --background: #fff;
      --border-radius: 20px;
      color: #000;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 14px;
    }
    p {
      line-height: 1.2;
      font-weight: 700;
      margin: 5px;
    }
  }

}
/* 
@media (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
	.installable-banner {
		top: 66px !important;
	}
}

@media (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
	.installable-banner {
		top: 58px !important;
	}
}

@media(max-width: 400px){
	.installable-banner {

		width: 280px;

		.installable-banner {

			width: 100%;
			height: 100px;
			padding-left: 140px;

			p {
				margin-bottom: 0px;
				font-size: 1em;
				font-weight: 700;
				letter-spacing: 0.5px;
				line-height: 1.2;
			}

			.add-button {
				left: 25px;
				font-size: 0.7em;
				width: 105px;
				height: 30px;
			}
		}

	}
}
*/