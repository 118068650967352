.player-play-button {
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  ion-button {
    --background: var(--ion-color-primary);
    --color: #fff;
    --border-radius: 50%;
    --padding-start: 5px;
    --padding-end: 5px;
    --box-shadow: 2px -2px 5px 0px rgba(0,0,0,0.75);
    min-width: 56px;
    width: 56px;
    max-width: 56px;
    min-height: 56px;
    height: 56px;
    max-height: 56px;
    margin: 0;
    font-size: 24px;
    &.play-button {
      ion-icon {
        padding-left: 5px;
      }
    }
    
  }
}